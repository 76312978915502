import React, {useEffect, useRef, useState} from "react";
import styles from "./Page.module.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import classNames from "classnames";

interface PageProps {
  children: React.ReactNode;
  title: string;
  hideMiniPage?: boolean;
  hideTitleInHeader?: boolean;
}

const Page = ({ children, title, hideMiniPage, hideTitleInHeader }: PageProps) => {
  const pageRef = useRef(null);
  const [scale, setScale] = useState(0);
  const [showMiniPage, setShowMiniPage] = useState(false);
  const [indicatorTop, setIndicatorTop] = useState(0);

  const scrollHandler = (e: any) => {
    setIndicatorTop(e.target.scrollTop);
  };

  const resizeHandler = () => {
    const { scrollHeight: contentHeight  } = (pageRef?.current as unknown as HTMLDivElement);
    setScale(window.innerHeight / contentHeight);
  };

  const handleWindowMouseMove = (e: any) => {
    const { innerWidth: width } = window;
    const { pageX } = e;

    if (width - 32 < pageX) {
      setShowMiniPage(true);
    } else {
      setShowMiniPage(false);
    }
  };

  useEffect(() => {
    document.title = title;

    resizeHandler();

    const pageElement = (pageRef?.current as unknown as HTMLDivElement);
    pageElement.addEventListener('scroll', scrollHandler);
    window.addEventListener('mousemove', handleWindowMouseMove);
    window.addEventListener('resize', resizeHandler);
    return () => {
      pageElement.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('mousemove', handleWindowMouseMove);
      window.removeEventListener('resize', resizeHandler);
    };
  }, [title]);

  return (
    <div id="page" className={styles.Page} ref={pageRef}>
      <Header title={!hideTitleInHeader ? title : undefined} />
      {children}
      <Footer />
      {!hideMiniPage && (
        <div className={classNames(styles.MiniPageContainer)}>
          <div className={classNames(styles.MiniPage, "absolute shadow-2xl w-full right-2 bg-white", {
            [styles.ShowMiniPage]: showMiniPage,
          })} style={{transform: `scale(${scale})`, transformOrigin: 'top right'}}>
            {children}
            <div className={classNames(styles.MiniPageIndicator)} style={{transform: `translateY(${indicatorTop}px)`}}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default Page;
