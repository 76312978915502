import React from "react";
import Page from "../components/Page/Page";
import { Section, SectionItem } from "../components/Layout";
import Image from "../components/Image/Image";
import Heading from "../components/Heading/Heading";
import RichText from "../components/RichText/RichText";
import Icon from "../components/Icon/Icon";

import avatar from "../assets/AA022.jpg";

import i2024_1_1 from "../assets/2024/fuerteventura/_MG_3673_exported.jpg";
import i2024_1_2 from "../assets/2024/fuerteventura/_MG_3674_exported.jpg";
import i2024_1_3 from "../assets/2024/fuerteventura/_MG_3678_exported.jpg";
import i2024_1_4 from "../assets/2024/fuerteventura/_MG_3686_exported.jpg";
import i2024_1_5 from "../assets/2024/fuerteventura/_MG_3689_exported.jpg";

import i2024_2_1 from "../assets/2024/800t/_MG_3582_exported.jpg";
import i2024_2_2 from "../assets/2024/800t/_MG_3583_exported.jpg";
import i2024_2_3 from "../assets/2024/800t/_MG_3584_exported.jpg";
import i2024_2_4 from "../assets/2024/800t/_MG_3585_exported.jpg";
import i2024_2_5 from "../assets/2024/800t/_MG_3587_exported.jpg";

import i2023_1_1 from "../assets/2023/helsinki/_MG_3448_exported.jpg";
import i2023_1_2 from "../assets/2023/helsinki/_MG_3451_exported.jpg";
import i2023_1_3 from "../assets/2023/helsinki/_MG_3459_exported.jpg";
import i2023_1_4 from "../assets/2023/helsinki/_MG_3465_exported.jpg";

import i2023_2_1 from "../assets/2023/lisbon/_MG_3488_exported.jpg";
import i2023_2_2 from "../assets/2023/lisbon/_MG_3493_exported.jpg";

import i2023_3_1 from "../assets/2023/berlin-spring/AA002A.jpg";
import i2023_3_2 from "../assets/2023/berlin-spring/AA008A.jpg";
import i2023_3_3 from "../assets/2023/berlin-spring/AA003A.jpg";
import i2023_3_4 from "../assets/2023/berlin-spring/AA013A.jpg";
import i2023_3_5 from "../assets/2023/berlin-spring/AA015A.jpg";
import i2023_3_6 from "../assets/2023/berlin-spring/AA024A.jpg";

import i2023_4_1 from "../assets/2023/switzerland/AA023A.jpg";
import i2023_4_2 from "../assets/2023/switzerland/AA031A.jpg";
import i2023_4_3 from "../assets/2023/switzerland/AA032A.jpg";
import i2023_4_4 from "../assets/2023/switzerland/AA034A.jpg";
import i2023_4_5 from "../assets/2023/switzerland/AA035A.jpg";

import i2022_1_1 from "../assets/2022/italy/__9_01460.jpg";
import i2022_1_2 from "../assets/2022/italy/_14_01465.jpg";
import i2022_1_3 from "../assets/2022/italy/_21_01323.jpg";
import i2022_1_4 from "../assets/2022/italy/22A_01507.jpg";
import i2022_1_5 from "../assets/2022/italy/31A_01590.jpg";
import i2022_1_6 from "../assets/2022/italy/32A_01591.jpg";

const Portfolio = () => {
  return (
    <Page title="Portfolio">
      <Section>
        <SectionItem>
          <Heading level={1} classes="mb-4 2xl:mb-8">Welcome!</Heading>
          <RichText center>
            <p>I'm glad you made it here.</p>
            <p>Enjoy the photos!</p>
            <Icon iconCode="expand_more" classes="mt-4" />
          </RichText>
        </SectionItem>
        <SectionItem>
          <Image path={avatar} aspectRatio="3/4" classes="sm:!h-2/3 md:!h-1/2" hasShadow disableLightbox />
        </SectionItem>
      </Section>

      <Section hideMarker>
        <SectionItem classes="bg-neutral-100">
          <Heading level={2}>2024</Heading>
        </SectionItem>
        <SectionItem>
          <Image path={i2024_1_4} aspectRatio="3/2" hasShadow />
        </SectionItem>
      </Section>

      <Section reverseOnLg>
        <SectionItem>
          <div className="relative flex flex-auto justify-center items-end w-full h-1/2">
            <Image path={i2024_1_2} aspectRatio="3/2" positionY="bottom" classes="!w-1/2 lg:!h-1/2 xl:!h-3/4 p-1" />
          </div>
          <div className="flex justify-center w-full lg:p-2 2xl:p-4">
            <Heading level={4}>Fuerteventura</Heading>
          </div>
          <div className="relative flex flex-auto w-full h-1/2">
            <Image
              path={i2024_1_5}
              aspectRatio="2/3"
              positionX="right"
              positionY="top"
              classes="!w-1/2 lg:!h-2/3 xl:!h-3/4 2xl:!h-full p-1"
            />
            <Image
              path={i2024_1_3}
              aspectRatio="3/2"
              positionX="left"
              positionY="top"
              classes="!w-1/2 !h-2/3 lg:!h-1/2 xl:!h-3/4 p-1"
            />
          </div>
        </SectionItem>
        <SectionItem>
          <Image path={i2024_1_1} aspectRatio={"3/2"} hasShadow />
        </SectionItem>
      </Section>

      <Section classes="bg-neutral-800 text-text-inverted after:!bg-primary-500 selection-dark" reverseOnLg>
        <SectionItem>
          <div className="w-full h-2/3 lg:h-1/2 xl:h-2/3">
            <Image path={i2024_2_5} aspectRatio={"3/2"} positionY="top" />
          </div>
          <div className="w-full h-1/3 lg:h-1/2 xl:h-1/3">
            <Heading level={4} center classes="mt-8 mb-4">The Underground</Heading>
            <RichText center>
              <p>Photos from Berlin's underground, shot on CineStil 800T.</p>
            </RichText>
          </div>
        </SectionItem>
        <SectionItem>
          <div className="flex w-full h-1/2">
            <Image path={i2024_2_1} aspectRatio={"2/3"} positionX="right" positionY="bottom" classes="pr-1 pb-1" />
            <Image path={i2024_2_2} aspectRatio={"2/3"} positionX="left" positionY="bottom" classes="pl-1 pb-1" />
          </div>
          <div className="flex w-full h-1/2">
            <Image path={i2024_2_3} aspectRatio={"2/3"} positionX="right" positionY="top" classes="pr-1 pt-1" />
            <Image path={i2024_2_4} aspectRatio={"2/3"} positionX="left" positionY="top" classes="pl-1 pt-1" />
          </div>
        </SectionItem>
      </Section>

      <Section hideMarker>
        <SectionItem classes="bg-neutral-100">
          <Heading level={2}>2023</Heading>
        </SectionItem>
        <SectionItem>
          <Image path={i2023_1_2} aspectRatio="2/3" hasShadow />
        </SectionItem>
      </Section>

      <Section>
        <SectionItem classes="bg-slate-50">
          <div className="flex justify-center flex-col items-center w-full h-1/2">
            <Heading level={3} center classes="mb-4">Helsinki</Heading>
            <RichText center>
              <p>Cold weather outside, warm glögi on the inside.</p>
              <Icon iconCode="coffee" />
            </RichText>
          </div>
          <div className="flex w-full h-1/2">
            <Image path={i2023_1_1} aspectRatio="4/5" hasShadow />
          </div>
        </SectionItem>
        <SectionItem classes="bg-slate-50">
          <div className="flex lg:flex-col items-center w-full h-full relative">
            <Image path={i2023_1_3} aspectRatio="2/3" positionX="left" positionY="top" />
            <RichText center>
              <p className="p-4 whitespace-nowrap">And stunning architecture.</p>
            </RichText>
            <Image path={i2023_1_4} aspectRatio="2/3" positionX="right" positionY="bottom" />
          </div>
        </SectionItem>
      </Section>

      <Section classes="bg-amber-50/25">
        <SectionItem ratioOnLg="2/5" alignY="top">
          <Image path={i2023_2_1} aspectRatio="2/3" classes="lg:!h-3/4" hasShadow />
        </SectionItem>
        <SectionItem ratioOnLg="1/5">
          <Heading level={2} vertical classes="mb-8">Lisbon</Heading>
          <RichText center>
            <p>A trip to the beach and back.</p>
          </RichText>
        </SectionItem>
        <SectionItem ratioOnLg="2/5" alignY="bottom">
          <Image path={i2023_2_2} aspectRatio="2/3" classes="lg:!h-3/4" hasShadow />
        </SectionItem>
      </Section>

      <Section reverseOnLg>
        <SectionItem>
          <div className="flex flex-row w-full h-full">
            <Image path={i2023_4_1} aspectRatio="3/2" positionX="right" positionY="bottom" classes="pb-1 pr-1" />
            <Image path={i2023_4_2} aspectRatio="3/2" positionX="left" positionY="bottom" classes="pb-1 pl-1" />
          </div>
          <Heading level={4} classes="lg:p-2 2xl:p-4">Switzerland</Heading>
          <div className="flex flex-row w-full h-full">
            <Image path={i2023_4_3} aspectRatio="3/2" positionX="right" positionY="top" classes="pt-1 pr-1" />
            <Image path={i2023_4_5} aspectRatio="3/2" positionX="left" positionY="top" classes="pt-1 pl-1" />
          </div>
        </SectionItem>
        <SectionItem>
          <Image path={i2023_4_4} aspectRatio="3/2" hasShadow />
        </SectionItem>
      </Section>

      <Section>
        <SectionItem>
          <div className="flex justify-center items-end w-full h-1/3 pb-2">
            <Heading level={2}>Berlin in Spring</Heading>
          </div>
          <div className="w-full h-2/3">
            <Image path={i2023_3_6} aspectRatio="2/3" positionX="right" positionY="top" classes="!w-1/3 pr-[5.333px] pt-[3.333px]" />
            <Image path={i2023_3_3} aspectRatio="2/3" positionX="center" positionY="top" classes="!w-1/3 px-[2.666px] pt-[3.333px]" />
            <Image path={i2023_3_4} aspectRatio="2/3" positionX="left" positionY="top" classes="!w-1/3 pl-[5.333px] pt-[3.333px]" />
          </div>
        </SectionItem>
        <SectionItem>
          <div className="flex w-full h-1/3">
            <Image path={i2023_3_5} aspectRatio="3/2" positionX="right" positionY="bottom" classes="pr-1 pb-[4.666px]" />
            <Image path={i2023_3_2} aspectRatio="3/2" positionX="left" positionY="bottom" classes="pl-1 pb-[4.666px]" />
          </div>
          <div className="flex w-full h-2/3">
            <Image path={i2023_3_1} aspectRatio="3/2" positionX="center" positionY="top" classes="pt-[3.333px]" />
          </div>
        </SectionItem>
      </Section>

      <Section hideMarker>
        <SectionItem classes="bg-neutral-100">
          <Heading level={2}>2022</Heading>
        </SectionItem>
        <SectionItem>
          <Image path={i2022_1_1} aspectRatio="2/3" hasShadow />
        </SectionItem>
      </Section>

      <Section>
        <SectionItem classes="!p-0">
          <div className="flex w-full h-1/2 pb-1">
            <Image path={i2022_1_2} />
          </div>
          <div className="flex flex-col justify-center lg:justify-start w-full h-1/2 px-4 py-8 md:p-8 lg:p-16">
            <Heading level={3} center classes="mb-4 md:mb-8 lg:mb-16">Italy</Heading>
            <RichText center>
              <p>This is where my photography journey started.</p>
              <p>Inspired by the results of the first films I received back from the lab, I continued diving into this rabbit-hole.</p>
              <p>Thank you for taking your time to look at what I created so far!</p>
            </RichText>
          </div>
        </SectionItem>
        <SectionItem>
          <div className="flex justify-center items-end w-full h-1/2">
            <Image path={i2022_1_3} aspectRatio="2/3" positionX="right" positionY="bottom" classes="!w-1/2 lg:!h-2/3 2xl:!h-full p-1" />
            <Image path={i2022_1_4} aspectRatio="3/2" positionX="left" positionY="bottom" classes="!w-1/2 p-1" />
          </div>
          <div className="flex w-full h-1/2">
            <Image path={i2022_1_5} aspectRatio="3/2" positionX="right" positionY="top" classes="!w-1/2 p-1" />
            <Image path={i2022_1_6} aspectRatio="3/2" positionX="left" positionY="top" classes="!w-1/2 p-1" />
          </div>
        </SectionItem>
      </Section>
    </Page>
  );
};

export default Portfolio;
