import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getRouterConfigForRouter } from "./routerConfig";

const router = createBrowserRouter(getRouterConfigForRouter());

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
