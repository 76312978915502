import React from "react";
import Portfolio from "./pages/Portfolio";
import Home from "./pages/Home";
import About from "./pages/About";

const routerConfig: any[] = [
  {
    path: "/",
    element: <Home />,
    title: "Home",
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
    title: "Portfolio",
  },
  {
    path: "/about",
    element: <About />,
    title: "About",
  },
];

const getRouterConfigForNav = () => routerConfig.map((item) => ({
  path: item.path,
  title: item.title,
}));

const getRouterConfigForRouter = () => routerConfig.map((item) => ({
  path: item.path,
  element: item.element,
}));

export { getRouterConfigForNav, getRouterConfigForRouter };
