import React from "react";
import styles from "./Layout.module.scss";
import cx from "classnames";

interface SectionProps {
  children?: React.ReactNode;
  reverseOnLg?: boolean;
  hideMarker?: boolean;
  classes?: string;
}

const Section = ({ children, classes, reverseOnLg, hideMarker }: SectionProps) => {
  return (
    <section className={cx(styles.Section, classes, {
      [styles.ReverseOnLg]: reverseOnLg,
      [styles.Marker]: !hideMarker,
    })}>
      {children}
    </section>
  );
};

export default Section;
