import React from "react";
import classNames from "classnames";
import styles from "./Nav.module.scss"
import {getRouterConfigForNav} from "../../routerConfig";
import {useLocation} from "react-router-dom";

interface NavProps {
  openState: boolean;
  setOpenState: React.Dispatch<React.SetStateAction<boolean>>;
}

const Nav = ({ openState, setOpenState }: NavProps) => {
  const items = getRouterConfigForNav();
  const { pathname: location } = useLocation();

  return (
    <div className={classNames(styles.NavContainer, {
      [styles.Open]: openState,
    })}>
      <nav className={classNames(styles.Nav)}>
        {items.map((item, i) => (
          location !== item.path
            ? <a key={i} className={classNames(styles.NavItem, styles.NavItemLink)} href={item.path}>{item.title}</a>
            : <span key={i} className={classNames(styles.NavItem)}>{item.title}</span>
        ))}
      </nav>
    </div>
  );
};

export default Nav;
