import React from "react";
import Page from "../components/Page/Page";
import {Section, SectionItem} from "../components/Layout";
import Heading from "../components/Heading/Heading";

const About = () => {
  return (
    <Page title="About" hideMiniPage>
      <Section>
        <SectionItem>
          <Heading level={6}>Under construction 🚧</Heading>
        </SectionItem>
        <SectionItem>
          <Heading level={6}>Please check back later!👷🏻</Heading>
        </SectionItem>
      </Section>
    </Page>
  )
};

export default About;
