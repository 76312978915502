import React from "react";
import classNames from "classnames";
import styles from "./Heading.module.scss";

interface HeadingProps {
  children?: React.ReactNode;
  level: number;
  center?: boolean;
  vertical?: boolean;
  classes?: string;
}

const Heading = ({ children, level, center, vertical, classes }: HeadingProps) => {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;
  return (
    <Tag className={classNames(styles.Heading, styles[`Level-${level}`], classes, {
      [styles.Center]: center,
      [styles.Vertical]: vertical,
    })}>{children}</Tag>
  )
}

export default Heading;
