import React from "react";
import classNames from "classnames";
import styles from "./RichText.module.scss";

interface RichTextProps {
  children?: React.ReactNode;
  center?: boolean;
  classes?: string;
}

const RichText = ({ children, center, classes }: RichTextProps) => {
  return (
    <div className={classNames(styles.RichText, classes, {
      [styles.Center]: center,
    })}>{children}</div>
  );
};

export default RichText;
