import React from "react";
import classNames from "classnames";
import styles from "./Layout.module.scss"

interface SectionItemProps {
  children?: React.ReactNode;
  alignX?: 'left' | 'center' | 'right' | 'stretch';
  alignY?: 'top' | 'center' | 'bottom' | 'stretch';
  ratioOnLg?: `${number}/${number}`;
  dense?: boolean;
  classes?: string;
}

const SectionItem = ({ children, dense, alignX = 'center', alignY = 'center', ratioOnLg = '1/2' , classes }: SectionItemProps) => {
  return (
    <div className={classNames(styles.SectionItem, styles[`lg-w-${ratioOnLg}`], classes, {
      [styles.Dense]: dense,
      [styles.LeftX]: alignX === 'left',
      [styles.CenterX]: alignX === 'center',
      [styles.RightX]: alignX === 'right',
      [styles.StretchX]: alignX === 'stretch',
      [styles.TopY]: alignY === 'top',
      [styles.CenterY]: alignY === 'center',
      [styles.BottomY]: alignY === 'bottom',
      [styles.StretchY]: alignY === 'stretch',
    })}>
      {children}
    </div>
  );
};

export default SectionItem;
