import React, {useState} from "react";
import classNames from "classnames";
import styles from "./Header.module.scss";
import IconButton from "../Button/IconButton";
import Nav from "../Nav/Nav";

interface HeaderProps {
  title?: string;
}

const Header = ({ title }: HeaderProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <>
      <header className={classNames(styles.Header, {
        [styles.MenuIsOpen]: menuIsOpen,
      })}>
        <div className={classNames(styles.HeaderRow)}>
          <IconButton iconCode={!menuIsOpen ? "menu" : "close"} onClick={() => setMenuIsOpen(!menuIsOpen)} classes={classNames(styles.HeaderMenuButton)} />
          <span className={classNames(styles.HeaderPageTitle)}>{process.env.REACT_APP_SITE_TITLE}</span>
          {title && <span className={classNames(styles.HeaderTitle)}>{title}</span>}
        </div>
        <div className={classNames(styles.HeaderRow)}>
          <Nav openState={menuIsOpen} setOpenState={setMenuIsOpen} />
        </div>
      </header>
    </>
  );
};

export default Header;
