import React, {useCallback} from "react";
import styles from "./Button.module.scss";
import cx from "classnames";
import Icon from "../Icon/Icon";

interface IconButtonProps {
  iconCode: string;
  link?: string;
  title?: string;
  classes?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
}

const IconButton = ({ iconCode, link, title, classes, onClick }: IconButtonProps) => {
  const ElementTag = link === undefined ? "button" : "a";

  const clickHandler = useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    onClick !== undefined && onClick(e);
  }, [onClick]);

  return (
    <ElementTag
      className={cx(styles.Button, styles.ButtonIcon, classes)}
      href={link}
      target={link?.startsWith('https://') ? '_blank' : undefined}
      title={title}
      onClick={clickHandler}
    >
      <Icon iconCode={iconCode} />
    </ElementTag>
  );
};

export default IconButton;
