import React from "react";
import Page from "../components/Page/Page";
import {Section, SectionItem} from "../components/Layout";
import Heading from "../components/Heading/Heading";
import RichText from "../components/RichText/RichText";
import Image from "../components/Image/Image";
import avatar from "../assets/_21_00324.jpg";

const Home = () => {
  return (
    <Page title="Home" hideMiniPage hideTitleInHeader>
      <Section hideMarker>
        <SectionItem dense classes="!pt-32">
          <Heading level={1} classes="mb-4 2xl:mb-8">Hey!</Heading>
          <RichText center>
            <p>I'm glad you made it here.</p>
            <p>Feel free to take a look around!</p>
          </RichText>
        </SectionItem>
        <SectionItem dense alignX="stretch" alignY="stretch" classes="min-h-[256px] md:min-h-[384px] lg:min-h-[512px] xl:min-h-[768px] lg:!pt-32">
          <Image path={avatar} aspectRatio="3/4" hasShadow disableLightbox />
        </SectionItem>
      </Section>
    </Page>
  )
};

export default Home;
