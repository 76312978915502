import React from "react";
import styles from "./Footer.module.scss";
import Icon from "../Icon/Icon";
import RichText from "../RichText/RichText";
import IconButton from "../Button/IconButton";

const Footer = () => {
  return (
    <footer className={styles.Footer}>
      <div className="flex justify-center w-full h-[400px] bg-white shadow-sm z-10">
        <div className="flex flex-col justify-center items-center w-full max-w-[1024px]">
          <RichText center classes="mb-2">
            <p>Like what you see? Let's get in touch!</p>
          </RichText>
          <div>
            <IconButton iconCode="person" link="https://www.linkedin.com/in/phillip-kiele/" title="LinkedIn" classes="mx-1" />
            <IconButton iconCode="photo_camera" link="https://www.instagram.com/kiephil_/" title="Instagram" classes="mx-1" />
            <IconButton iconCode="cloud" link="https://www.soundcloud.com/kiele_wav" title="SoundCloud" classes="mx-1" />
          </div>
        </div>
      </div>
      <div className="footer-copy flex justify-center w-full text-sm text-black/75 z-0 bg-neutral-50 select-none">
        <div className="footer-row-inner flex items-center w-full max-w-[1024px] h-16">
          <div className="inline-flex items-center p-4">
            <Icon iconCode="copyright" classes="mr-1" />
            {new Date().getUTCFullYear()}
          </div>
          <span className="border-[1px] rounded h-1/2" />
          <div className="flex-grow p-4">Made with 💗 and 🧠</div>
          <span className="border-[1px] rounded h-1/2" />
          <div className="px-4">
            <IconButton iconCode="coffeeexposure_plus_1" title="Gift me a coffee! [soon]" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
