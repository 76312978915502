import React from "react";
import classNames from "classnames";

interface IconProps {
  iconCode: string;
  classes?: string;
}

const Icon = ({ iconCode, classes }: IconProps) => {
  return (
    <span className={classNames("material-symbols-outlined", classes)}>{iconCode}</span>
  );
};

export default Icon;
