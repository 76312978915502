import React, {useCallback, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import styles from "./Image.module.scss";

interface ImageProps {
  path: string;
  aspectRatio?: `${number}/${number}`;
  positionX?: 'left' | 'center' | 'right';
  positionY?: 'top' | 'center' | 'bottom';
  hasShadow?: boolean;
  disableLightbox?: boolean;
  parallaxPercentage?: number;
  classes?: string;
}

const Image = ({ path, aspectRatio, positionX = 'center', positionY = 'center', hasShadow, disableLightbox, parallaxPercentage = 0.1, classes = '' }: ImageProps) => {
  const imageContainerRef = useRef(null);
  const imageRef = useRef(null);
  const imageParallaxRef = useRef(null);

  const [containerIsPortrait, setContainerIsPortrait] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [numericRatio, setNumericRatio] = useState(1);
  const [parallaxTranslation, setParallaxTranslation] = useState(0);

  const resizeHandler = useCallback(() => {
    if (aspectRatio) {
      const {
        clientWidth: containerWidth,
        clientHeight: containerHeight
      } = (imageContainerRef?.current as unknown as HTMLDivElement);
      const containerAspectRatio = containerWidth / containerHeight;
      const imageAspectRatio = (aspectRatio.split('/')[0] as unknown as number) / (aspectRatio.split('/')[1] as unknown as number);

      setNumericRatio(imageAspectRatio);
      setContainerIsPortrait(containerAspectRatio < imageAspectRatio);
    }
  }, [aspectRatio]);

  const scrollHandler = useCallback((e: any) => {
    const { clientHeight: pageContainerHeight } = e.target;
    const { top: imageTop, height: imageHeight } = (imageRef?.current as unknown as HTMLDivElement)?.getBoundingClientRect();
    const { height: imageParallaxHeight } = (imageParallaxRef?.current as unknown as HTMLDivElement)?.getBoundingClientRect();

    const offsetDistance = imageTop + (imageHeight / 2) - (pageContainerHeight / 2);
    const offsetFactor = Math.min(Math.max(offsetDistance / (pageContainerHeight / 2 + imageHeight / 2), -1), 1);
    const parallaxRange = (imageParallaxHeight - imageHeight) / 2;
    const offset = parallaxRange * offsetFactor;
    setParallaxTranslation(-offset);
  }, []);

  useEffect(() => {
    const pageElement = document.getElementById('page');
    resizeHandler();

    window.addEventListener('resize', resizeHandler);
    pageElement?.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
      pageElement?.removeEventListener('scroll', scrollHandler);
    };
  }, [resizeHandler, scrollHandler]);

  return (
    <>
      <div className={classNames(styles.ImageContainer, classes, {
        [styles.LeftX]: positionX === 'left',
        [styles.RightX]: positionX === 'right',
        [styles.TopY]: positionY === 'top',
        [styles.BottomY]: positionY === 'bottom',
      })} ref={imageContainerRef}>
        <div
          className={classNames(styles.Image, {
            [styles.ContainerIsTooNarrow]: aspectRatio && containerIsPortrait,
            [styles.ContainerIsTooShort]: aspectRatio && !containerIsPortrait,
            [styles.HasShadow]: hasShadow,
            [styles.NoLightbox]: disableLightbox,
            [styles.IgnoreAspectRatio]: !aspectRatio,
          })}
          style={{
            aspectRatio: numericRatio,
          }}
          onClick={() => !disableLightbox && setLightboxOpen(true)}
          ref={imageRef}
        >
          <div className={classNames(styles.ImageParallaxWrapper)}>
            <div className={classNames(styles.ImageParallax)} style={{
              backgroundImage: `url(${path})`,
              transform: `translateY(${parallaxTranslation}px)`,
              top: `-${parallaxPercentage * 100}%`,
              bottom: `-${parallaxPercentage * 100}%`,
            }} ref={imageParallaxRef} />
          </div>
        </div>
      </div>

      {!disableLightbox && (
        <div className={classNames(styles.Lightbox, {
          [styles.Open]: lightboxOpen,
        })} onClick={() => setLightboxOpen(false)}>
          <div className="relative w-full h-full bg-contain bg-center bg-no-repeat" style={{backgroundImage: `url('${path}')`}} />
        </div>
      )}
    </>
  );
};

export default Image;
